import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import SimpleReactValidator from 'simple-react-validator';

import HeaderText from '../../components/communal/HeaderText';
import InteractionTimeout from '../../components/communal/InteractionTimeout';
import SetTitle from '../../components/communal/SetTitle';
import DestinationSwitch from '../../components/shared/DestinationSwitch';
import PersonFields from '../../components/shared/PersonFields';

import { parseDate } from '../../utilities/generic/Common.js'
import { handleAutocomplete, handlePersonChange, handlePersonSubmit, handlePlannedEventSubmit, displayAutocompletePersonForSelecting, displayAutocreateNotAllowedMessage, photoRequired, cleanupVideoStream, backgroundMatchPerson } from '../../utilities/communal/Forms.js'

class Arriving extends Component {

  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();

    this.photoRequired = photoRequired.bind(this);
    this.handleAutocomplete = handleAutocomplete.bind(this);
    this.handlePersonChange = handlePersonChange.bind(this);
    this.handlePersonSubmit = handlePersonSubmit.bind(this);
    this.handlePlannedEventSubmit = handlePlannedEventSubmit.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.displayPlannedEventsModal = this.displayPlannedEventsModal.bind(this);
    this.renderArrivalPlannedEvents = this.renderArrivalPlannedEvents.bind(this);
    this.renderHostingPlannedEvents = this.renderHostingPlannedEvents.bind(this);
  }

  state = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    planned_event_ids: [],
    arriving_planned_events: [],
    hosting_planned_events: [],

    autocompletePerson: "",
    autocompleteField: "",
    rejectedAutocompletePerson: "",

    autoCreateMessage: false,

    photoRequired: false,
    photoCaptured: null,

    videoStreams: [],

    validDetails: false,
    errors: "",
    error: "",

    // Very important for `directionHandler()`. Do not remove.
    direction: "arriving"
  }

  handleMultiCheckbox(event) {
    let name = parseInt(event.target.name);
    let value = event.target.checked;

    var planned_event_ids = this.state.planned_event_ids

    if (value === true) {
      if (!planned_event_ids.includes(name)) {
        planned_event_ids.push(name)
      }
    }
    else if (value === false) {
      if (planned_event_ids.includes(name)) {
        let index = planned_event_ids.indexOf(name)
        planned_event_ids.splice(index, 1)
      }
    }

    this.setState({
      planned_event_ids: planned_event_ids,
    });
  }

  displayPlannedEventsModal() {
    if (this.state.arriving_planned_events.length > 0 || this.state.hosting_planned_events.length > 0) {
      return (
        <div className="planned-events-container">
          <div className="planned-events-content">
            <div className="value">
              {this.renderArrivalPlannedEvents()}
              {this.renderHostingPlannedEvents()}
            </div>
            <button className="planned-events-button" onClick={this.handlePlannedEventSubmit}>Continue</button>
          </div>
        </div>
      )
    }
  }

  renderArrivalPlannedEvents() {
    if (this.state.arriving_planned_events.length > 0) {
      return (
        <div className="planned-events-list">
          Please check in for all the appointments that apply to your visit:
          {this.state.arriving_planned_events.map((arriving_planned_event) => (
            <div className="planned-events-arriving" key={arriving_planned_event.id}>
              <input type="checkbox" id={arriving_planned_event.id} name={arriving_planned_event.id} checked={this.state.planned_event_ids.includes(arriving_planned_event.id)} onChange={this.handleMultiCheckbox} />
              <label htmlFor={arriving_planned_event.id}>{arriving_planned_event.title && <span><strong>{arriving_planned_event.title}</strong> - </span>}Visiting <strong>{arriving_planned_event.first_host}</strong> at <strong>{parseDate(arriving_planned_event.start_time)}</strong></label>
            </div>
          ))}
        </div>
      )
    }
  }

  renderHostingPlannedEvents() {
    if (this.state.hosting_planned_events.length > 0) {
      return (
        <div className="planned-events-list">
          Please be aware that you have these appointments today:
          {this.state.hosting_planned_events.map((hosting_planned_event) => (
            <div key={hosting_planned_event.id}>
              <div id={hosting_planned_event.id}>{hosting_planned_event.title && <span><strong>{hosting_planned_event.title}</strong> - </span>}<strong>{hosting_planned_event.first_arriving}</strong> is visiting at <strong>{parseDate(hosting_planned_event.start_time)}</strong></div>
            </div>
          ))}
        </div>
      )
    }
  }

  render() {
    const cookies = new Cookies()
    const token = cookies.get('device_token')

    if (token === undefined) {
      return <Redirect to="/setup/user"/>
    }

    if (this.props.location.state === undefined) {
      return <Redirect to="/404" />
    }

    let profile = this.props.location.state.profile
    let person = this.state.autocompletePerson

    const { first_name, last_name, email, phone, direction } = this.state;

    if (this.state.validDetails) {
      // wait until photo is taken if it is needed
      if (this.state.photoRequired === false || this.state.photoCaptured === true) {
        if (typeof person === "string") {
          person = backgroundMatchPerson("covid_pass_expiry_date", this)
        }

        return <DestinationSwitch current_page="arriving" profile={profile} person={person} arriving_app_id={this.state.app_id} direction="arriving" planned_event_ids={this.state.planned_event_ids} />
      }
    }

    const flow_order = localStorage.flow_order || "profiles"

    return (
      <div className="main-page arriving-page back-button">
        <InteractionTimeout seconds={60} />

        <SetTitle title={"Arriving"} />
        <HeaderText />

        <div className="content">
          <div className="widget form">
            <form autoComplete="off" onSubmit={this.handlePersonSubmit}>
              <div className="message">
                {profile.text_on_check_in}
              </div>

              <PersonFields
                profile={profile} personal={false}
                first_name={first_name} last_name={last_name} email={email} phone={phone}
                handleChange={this.handlePersonChange} validator={this.validator}
              />

              <br />
              <input type="submit" value="Submit" />
            </form>
            <video id="video-container" className="hidden" />
            <canvas id="canvas-container" className="hidden" />

            {displayAutocompletePersonForSelecting("first_name", this)}
            {displayAutocompletePersonForSelecting("last_name", this)}
            {displayAutocompletePersonForSelecting("email", this)}
            {displayAutocompletePersonForSelecting("phone", this)}

            {displayAutocreateNotAllowedMessage(this)}

            {this.displayPlannedEventsModal()}
          </div>
        </div>

        <div className="back">
          {flow_order === "profiles" ?
            <Link to={{ pathname: '/arriving-or-leaving', state: { profile: profile } }} className="widget">Back</Link>
          :
            <Link to={{ pathname: '/profiles', state: { direction: direction } }} className="widget">Back</Link>
          }
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (this.photoRequired()) {
      this.setState({ photoRequired: true });

      const video = document.getElementById('video-container');

      if (!video.srcObject) {
        navigator.mediaDevices.getUserMedia({ video:true })
          .then((stream) => {
            // permission obtained, no need to track previous rejections!
            localStorage.removeItem("rejectedPhotos");

            this.setState(prevState => {
              let streams = [...prevState.videoStreams]
              streams.push(stream)
              return ({videoStreams: streams})
            })

            video.srcObject = stream;

            // Needed for Apple devices
            video.play()
              .catch(error => {})
          })
          .catch((error) => {
            if (error.message.includes("not allowed")) {
              // if permission is rejected, we keep a running count of photos not taken due to rejection
              let rejectedPhotos = JSON.parse(localStorage.getItem("rejectedPhotos")) || 0;
              localStorage.setItem("rejectedPhotos", rejectedPhotos + 1)
            }
          })
      }
    }
  }

  componentWillUnmount() {
    this.state.videoStreams.forEach((stream) => {
      cleanupVideoStream(stream)
    })
  }
}

export default Arriving;
