import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import SimpleReactValidator from 'simple-react-validator';

import HeaderText from '../../components/personal/HeaderText';
import SetTitle from '../../components/personal/SetTitle';
import DestinationSwitch from '../../components/shared/DestinationSwitch';
import PersonFields from '../../components/shared/PersonFields';

import { parseDate } from '../../utilities/generic/Common.js'
import { handleChange } from '../../utilities/generic/Forms.js'
import { handleAutocomplete, handlePersonSubmit, displayAutocompletePersonForSelecting, displayAutocreateNotAllowedMessage, submitEvent } from '../../utilities/personal/Forms.js'

class Arriving extends Component {

  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();

    this.handleAutocomplete = handleAutocomplete.bind(this);
    this.handleChange = handleChange.bind(this);
    this.handlePersonSubmit = handlePersonSubmit.bind(this);
    this.submitEvent = submitEvent.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.displayPlannedEventsModal = this.displayPlannedEventsModal.bind(this);
    this.renderArrivalPlannedEvents = this.renderArrivalPlannedEvents.bind(this);
    this.renderHostingPlannedEvents = this.renderHostingPlannedEvents.bind(this);
  }

  state = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    planned_event_ids: [],
    arriving_planned_events: [],
    hosting_planned_events: [],
    relationships: [],
    custom_attributes: [],
    covid_pass_expiry_date: null,
    covid_pass_exemption: null,
    feedback_answer_times: null,
    app_id: "",
    autocompleteDetails: {},
    autocomplete: false,

    autoCreateMessage: false,

    updated: "",
    errors: "",
    error: "",
    direction: "arriving"
  }

  handleMultiCheckbox(event) {
    let name = parseInt(event.target.name);
    let value = event.target.checked;

    var planned_event_ids = this.state.planned_event_ids

    if (value === true) {
      if (!planned_event_ids.includes(name)) {
        planned_event_ids.push(name)
      }
    }
    else if (value === false) {
      if (planned_event_ids.includes(name)) {
        let index = planned_event_ids.indexOf(name)
        planned_event_ids.splice(index, 1)
      }
    }

    this.setState({
      planned_event_ids: planned_event_ids,
    });
  }

  displayPlannedEventsModal() {
    if (this.state.arriving_planned_events.length > 0 || this.state.hosting_planned_events.length > 0) {
      return (
        <div className="planned-events-container">
          <div className="planned-events-content">
            <div className="value">
              {this.renderArrivalPlannedEvents()}
              {this.renderHostingPlannedEvents()}
            </div>
            <button className="planned-events-button" onClick={this.submitEvent}>Continue</button>
          </div>
        </div>
      )
    }
  }

  renderArrivalPlannedEvents() {
    if (this.state.arriving_planned_events.length > 0) {
      return (
        <div className="planned-events-list">
          Please check in for all the appointments that apply to your visit:
          {this.state.arriving_planned_events.map((arriving_planned_event) => (
            <div className="planned-events-arriving" key={arriving_planned_event.id}>
              <input type="checkbox" id={arriving_planned_event.id} name={arriving_planned_event.id} checked={this.state.planned_event_ids.includes(arriving_planned_event.id)} onChange={this.handleMultiCheckbox} />
              <label htmlFor={arriving_planned_event.id}>{arriving_planned_event.title && <span><strong>{arriving_planned_event.title}</strong> - </span>}Visiting <strong>{arriving_planned_event.first_host}</strong> at <strong>{parseDate(arriving_planned_event.start_time)}</strong></label>
            </div>
          ))}
        </div>
      )
    }
  }

  renderHostingPlannedEvents() {
    if (this.state.hosting_planned_events.length > 0) {
      return (
        <div className="planned-events-list">
          Please be aware that you have these appointments today:
          {this.state.hosting_planned_events.map((hosting_planned_event) => (
            <div key={hosting_planned_event.id}>
              <div id={hosting_planned_event.id}>{hosting_planned_event.title && <span><strong>{hosting_planned_event.title}</strong> - </span>}<strong>{hosting_planned_event.first_arriving}</strong> is visiting at <strong>{parseDate(hosting_planned_event.start_time)}</strong></div>
            </div>
          ))}
        </div>
      )
    }
  }

  render() {
    if (this.props.location.state === undefined) {
      return <Redirect to="/personal/404" />
    }

    let profile = this.props.location.state.profile
    let token = this.props.location.state.token
    let previous_page = this.props.location.state.previous_page

    const { first_name, last_name, email, phone, relationships, covid_pass_exemption, covid_pass_expiry_date, custom_attributes, feedback_answer_times, updated, errors, error } = this.state;

    if (updated) {
      let person = { covid_pass_exemption, covid_pass_expiry_date, custom_attributes, feedback_answer_times, relationships }

      return <DestinationSwitch current_page="arriving" profile={profile} person={person} arriving_app_id={this.state.app_id} direction="arriving" token={token} personal={true} planned_event_ids={this.state.planned_event_ids} />
    }

    if (errors) {
      return (
        <div className="errors">
          Sorry, looks like you have some errors:
          <ul>
            {errors.errors.map((error) => (
              <li>{error}</li>
            ))}
          </ul>
        </div>
      )
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    return (
      <div className="main-page arriving-page back-button">
        <SetTitle title={"Arriving"} profile={profile} />
        <HeaderText message={profile.welcome_message} />

        <div className="content">
          <div className="widget form">
            <form autoComplete="off" onSubmit={this.handlePersonSubmit}>
              <div className="message">
                {profile.text_on_check_in}
              </div>

              <PersonFields
                profile={profile} personal={true}
                first_name={first_name} last_name={last_name} email={email} phone={phone}
                handleChange={this.handleChange} validator={this.validator}
              />

              <br />
              <input type="submit" value="Submit" />
            </form>

            {displayAutocompletePersonForSelecting(this)}

            {displayAutocreateNotAllowedMessage(this)}

            {this.displayPlannedEventsModal()}
          </div>
        </div>

        <div className="back">
          {previous_page === "profiles" ? (
            <Link to={{ pathname: `/personal/new/${encodeURIComponent(token)}`, search: "?page=home" }} className="widget">
              Back
            </Link>
          ) : (
            <Link to={{ pathname: `/personal/new/${encodeURIComponent(token)}` }} className="widget">
              Back
            </Link>
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (this.props.location.state === undefined) {
      return
    }

    let token = this.props.location.state.token

    let cookies = new Cookies()
    let person_token = cookies.get('person_token')

    if (person_token !== undefined) {
      var headers = new Headers();
      headers.append("Content-Type", "application/json");

      var json = JSON.stringify({
        "personal": {
          "token": token,
          "person_token": person_token,
          "profile_id": this.props.location.state.profile.id,
          "type_of_person": this.props.location.state.profile.type_of_person
        }
      })

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: json,
        redirect: 'follow'
      };

      var errorsInResponse = false

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/d/personal/person_check`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401 || response.status === 422) {
            errorsInResponse = true
            return response.json();
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          if (errorsInResponse) {
            let cookies = new Cookies()
            cookies.remove('person_token', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN })
          } else {
            this.setState({
              autocompleteDetails: data,
              autocomplete: true,
              loaded: true
            })
          }
        })
        .catch(error => this.setState({ error: error, loaded: true }))
    }
  }
}

export default Arriving;
